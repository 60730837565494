<template lang="pug">
.organization-type-list
  .d-flex.justify-between.align-center
    .text-gray-900.text-2xl Organization Types
    div
      v-btn(
        color="primary"
        @click="isOrgTypeCreateEditOpen = true"
        depressed
      ) + New Org Types

  .d-flex.justify-between.mt-16.gap-8
    div.flex-1
      v-text-field(
        class="mb-4"
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        clearable
      )

    .flex-1
      v-select(
        label="IRS Designations"
        v-model="irsDesignation"
        outlined
        :loading="irsDesignationPending"
        multiple
        dense
        item-value="id"
        item-text="name"
        :items="irsDesignations"
      )

  v-data-table.border.shadow(
    must-sort
    :items="orgTypes"
    :loading="isPending"
    :headers="headers"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :footer-props="{ itemsPerPageOptions:[25,50,100,-1] }"
    :options.sync="options"
    no-data-text="No org types found"
  )
    template(
      v-slot:item.actions="{ item: orgType }"
    )
      v-menu( left offset-y )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            color="gray"
            v-bind="attrs"
            v-on="on"
            depressed
            small
            fab
          )
            v-icon mdi-dots-vertical
        v-list.divide-y
          v-list-item(
            class="hover:bg-gray-200 cursor-pointer"
            @click="isOrgTypeCreateEditOpen = true; editingOrgType = orgType"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-square-edit-outline
            v-list-item-content
              v-list-item-title.cursor-pointer Edit

  OrgTypeCreateEditDialog(
    :isOpen="isOrgTypeCreateEditOpen"
    @close="isOrgTypeCreateEditOpen = false; editingOrgType = null; findOrgTypes()"
    :editingOrgType="editingOrgType"
    v-if="isOrgTypeCreateEditOpen"
  )

</template>

<script>
import { useFind } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import OrgTypeCreateEditDialog from '@/views/organization-types/OrgType.CreateEdit.Dialog'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'

export default {
  name: 'OrgTypeList',

  components: {
    OrgTypeCreateEditDialog
  },

  methods: {},

  setup(props, { root: { $FeathersVuex } }) {
    const editingOrgType = ref(null)
    const isOrgTypeCreateEditOpen = ref(false)

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'Name',
          value: 'name',
          filterable: true
        },
        {
          text: 'IRS Designation',
          value: 'irsDesignation.name',
          sortable: false
        },
        {
          text: 'Address',
          value: 'fullAddress',
          sortable: false
        }
      ],
      [],
      true
    );

    const irsDesignation = ref([1,2,3,4,5,6]);
    const { IRSDesignation } = $FeathersVuex.api;
    const { items: irsDesignations, isPending: irsDesignationPending } = useFind({
      model: IRSDesignation,
      params: {
        query: { selectable: true }
      }
    });

    const filter = computed(() => {
      return {
        $eager: '[irsDesignation, state]',
        irsDesignationId: { $in: irsDesignation.value }
      }
    });
    const {
      items: orgTypes,
      isPending,
      pagination,
      findItems: findOrgTypes,
      options,
      search
    } = useDataTableFind(
      'organization-types',
      filterableColumns,
      filter,
      { sortBy: ['name'] }
    )

    return {
      pagination,
      isPending,
      headers,
      orgTypes,
      options,
      editingOrgType,
      findOrgTypes,
      search,

      irsDesignationPending,
      irsDesignation,
      irsDesignations,

      isOrgTypeCreateEditOpen
    }
  }
}
</script>